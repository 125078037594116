import React, {useEffect, useState} from 'react';
import {UserListViewModel} from "../ViewModels/UserListViewModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {UserEditViewModel} from "../ViewModels/UserEditViewModel";
import {BranchLookupBranchViewModel} from "../ViewModels/BranchLookupBranchViewModel";
import {Button, Input} from "reactstrap";
import {UserDeleteConfirmation} from "./UserDeleteConfirmation";
//@ts-ignore
import Select from "react-select";
import {formatPhoneNumber} from "../../../../Utilities/FormatPhoneNumber";
import {toast} from "react-toastify";
import user from "../../../User";

interface IUserRowProps {
    index: number;
    row: UserListViewModel;
    branchData: BranchLookupBranchViewModel[];
    handleUpdate: (index: number, viewModelToUpdate: UserListViewModel) => void;
    handleDelete: (index: number, id: string) => void;
}

export const UserRow = ({row, branchData, index, handleUpdate, handleDelete}: IUserRowProps) => {
    const {userId, name, branchName, branchId, phone, email, roles} = row;
    const [updatedRow, setUpdatedRow] = useState({
        userId: "",
        name: "",
        email: "",
        branch: {
            name: "",
            branchId: 0
        },
        phone: ""
    } as UserEditViewModel)
    const [isSaved, setIsSaved] = useState(false);
    const [isDropdownOpen, setIsDropDownOpen] = useState(false);
    const [popup, setPopup] = useState({
        show: false,
        id: ""
    });

    const handleChange = (e: any) => {
        e.persist();
        setUpdatedRow(prevState => {
            return {
                ...updatedRow,
                [e.target.name]: e.target.value
            }
        });
    }

    const handlePhoneChange = (e: any) => {
        let number = formatPhoneNumber(e.target.value);
        setUpdatedRow({
            ...updatedRow,
            phone: number
        });
    }

    const populateBranches = () => {
        return (
            <Input type="select" onChange={((e: any) => handleDropDownClick(e))} value={updatedRow.branch.branchId}>
                {branchData.map((data, index: number) => {
                    return (
                        <option key={data.branchId} value={data.branchId}>{data.branchName}</option>
                    )
                })}
            </Input>
        )
    }

    const deleteRow = (id: string) => {
        setPopup({
            show: true,
            id: id
        })
    }

    const handleDeleteConfirmation = (check: boolean) => {
        if (check) {
            if (popup.show && popup.id !== "") {
                handleDelete(index, popup.id);
                setPopup({
                    show: false,
                    id: popup.id
                })
                toggle();
            }
        } else {
            setPopup({
                show: false,
                id: ""
            })
        }
    }

    const handleDropDownClick = (e: any) => {
        e.persist();
        setUpdatedRow(prevState => {
            return {
                ...updatedRow,
                branch: {
                    name: e.target.name,
                    branchId: e.target.value
                }
            }
        })
    }

    const toggle = () => {
        setIsDropDownOpen(!isDropdownOpen);
    }

    const handleSaveChanges = () => {
        if(updatedRow.name == "") return toast("Name is empty", {type: "error"});
        if(updatedRow.email == "") return toast("Email is empty", {type: "error"});
        if(updatedRow.phone == "") return toast("Phone Number is empty", {type: "error"});
        handleUpdate(index, {
            userId: row.userId,
            name: updatedRow.name,
            email: updatedRow.email,
            roles,
            branchName: updatedRow.branch.name,
            branchId: updatedRow.branch.branchId,
            phone: updatedRow.phone
        })
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 1250);
    }

    useEffect(() => {
        setUpdatedRow(prevState => { 
            return {...updatedRow, name, email, branch: {name: branchName, branchId: branchId}, phone}})
    }, [userId])

    return (
        <tr key={index} className="p-5">
            <td>
                <input className="form-control" name="name" type="text" required
                       onChange={handleChange} value={updatedRow.name}>
                </input>
            </td>
            <td>
                <input className="form-control" name="email" type="email" required
                       onChange={handleChange} value={updatedRow.email}>
                </input>
            </td>
            <td>
                <input className="form-control" name="phone" type="tel" required
                       onChange={handlePhoneChange} value={updatedRow.phone}>
                </input>
            </td>
            <td>
                {populateBranches()}
            </td>
            <td className="align-middle text-center">
                {
                    isSaved
                        ?
                        <FontAwesomeIcon className="text-success" icon={faCheck}/>
                        :
                        <a className="btn btn-secondary text-white" onClick={handleSaveChanges} type="submit">Save</a>
                }
                <Button className="btn btn-danger ml-4" onClick={() => deleteRow(userId)}>Delete</Button>
                {popup.show && (
                    <UserDeleteConfirmation handleDeleteConfirmation={handleDeleteConfirmation} toggle={toggle}
                                            popup={popup}/>
                )}
            </td>
        </tr>
    )
}