import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {IUserService} from "../Services/IUserService";
import {UserListViewModel} from "../ViewModels/UserListViewModel";
import {UserTable} from "./UserTable";
import {UserUpdateViewModel} from "../ViewModels/UserUpdateViewModel";
import {Button} from "reactstrap";
import {BranchLookupBranchViewModel} from "../ViewModels/BranchLookupBranchViewModel";

interface IUserControllerProps {
    userService: IUserService
}

export const UserController = ({userService}: IUserControllerProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isBranchesLoading, setIsBranchesLoading] = useState(false);
    const [tableData, setTableData] = useState([] as UserListViewModel[]);
    const [branches, setBranches] = useState([] as BranchLookupBranchViewModel[]);
    const history = useHistory();

    const getTableData = async () => {
        setIsLoading(true);
        userService.GetUserList()
            .then((data) => {
                setTableData(prevState => data);
                setIsLoading(false);
            }).catch((error: Error) => {
            alert(error);
        })
    }
    
    const getBranches = async () => {
        userService.GetBranches()
            .then((data )=> {
                setBranches(data);
                setIsBranchesLoading(false);
                return data;
            })
    }

    const handleUpdate = (index: number, data: UserListViewModel) => {
        const tempTable = tableData;
        tempTable[index] = data;
        setTableData((prevState) => tempTable);
        userService.UpdateSubscriptionUser(data.userId,
            {
                userId: data.userId,
                name: data.name,
                email: data.email,
                branchId: data.branchId,
                phone: data.phone
            } as UserUpdateViewModel).then(x => setIsLoading(false)).catch(e => { alert('There was a problem saving: ' + e.message);
            getTableData().catch((error: Error) => console.log(error));
        })
    }

    const handleAdd = (data: UserListViewModel) => {
            setIsLoading(true);
            userService.CreateSubscriptionUser(
            {
                userId: data.userId,
                name: data.name,
                email: data.email,
                branchId: data.branchId,
                phone: data.phone
            } as UserUpdateViewModel)
            .then((x: any) => {
                getTableData().catch((error: Error) => console.log(error));
                // update state to show add
            })
            .catch(ex => {
                // figure out what we need to do:
                alert('There was a problem saving: ' + ex)
                getTableData().catch((error: Error) => console.log(error));
            });
    }

    const handleDelete = (index: number, id: string) => {
        userService.RemoveSubscriptionUser(id)
            .then(x => {
                    setTableData(tableData.filter(x => x.userId !== id));
                    getTableData().catch((error: Error) => console.log(error));
                }
            )
            .catch(ex => {
                alert('There was a problem deleting that row: ' + ex)
                getTableData()            
            });
    }

    useEffect(() => {
        getTableData().catch((error: Error) => console.log(error));
        getBranches().catch((error: Error) => console.log(error));
    }, [])
    
    return (
        <>
            <div className="m-5 pl-3">
                <Button color="primary" onClick={() => history.goBack()} className="mb-3">Go Back</Button>
                
                <div className="card main-card">
                    <div className="card-header">CTSI Subscription Ordering Users</div>
                    {/*<div className="card-title"><div className="card-header"></div></div>*/}
                    <div className="card-body p-0">
                        <UserTable tableData={tableData} branchData={branches} handleAdd={handleAdd} handleUpdate={handleUpdate} handleDelete={handleDelete} isLoading={isLoading}/>
                    </div>
                </div>
            </div>

            <div className="justify-content-center col-md-12">
              
            </div>
        </>
        
    );
}