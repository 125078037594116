import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";


interface IUserDeleteConfirmationProps {
    handleDeleteConfirmation: (check: boolean) => void;
    toggle: any;
    popup: {show: boolean, id: string};
}

export const UserDeleteConfirmation = ({handleDeleteConfirmation, toggle, popup}: IUserDeleteConfirmationProps) => {
    return (
        <Modal  backdrop={true} toggle={toggle} size="sm" isOpen={popup.show}>
            <ModalHeader>Are you sure?</ModalHeader>
            <ModalBody className="d-flex justify-content-center">
                <button className="btn btn-danger mr-2" onClick={() => handleDeleteConfirmation(true)}>Yes</button>
                <button className="btn btn-secondary ml-2" onClick={() => handleDeleteConfirmation(false)}>No</button>
            </ModalBody>
        </Modal>
    )
}