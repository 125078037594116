export async function handleResponse(response: any) {
  if (response.status === 401) {
    console.log("Unauthorized!");
    return Promise.reject("Unauthorized");
  }
  if (response.status === 400) {
    return await response.json().then((x: any) => {
      var text = x.message != null ? x.message : x.title;
      return Promise.reject(text);
    });
  }
  if (response.status === 409) {
    return await response.json().then((x:any) => {
      var text = x.message != null ? x.message : x.title;
      return Promise.reject(text);
    });
  }
  if (response.status === 413) {
    return Promise.reject(`${response.statusText}`);
  }
  if (response.status === 500) {
    return await response
      .json()
      .then((x: any) => Promise.reject(x.detail))
      .catch((x: any) => Promise.reject("Cannot communicate with Server"));
  }
  if (response.status === 204) {
    return null;
  }
  if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
    console.log("error retrieving response");
    return Promise.reject("Cannot communicate with server.");
  }

  return response.json();
}
