import {IUserService} from "./IUserService";
import {authHeader, handleResponse} from "../../../../services/helpers";
import {UserUpdateViewModel} from "../ViewModels/UserUpdateViewModel";
import {BranchLookupBranchViewModel} from "../ViewModels/BranchLookupBranchViewModel";

export default class UserService implements IUserService {
    baseUrl: string = '/api/user/';
    
    GetUserList = async () => {
        return await this.GetRequest("list");
    }

    async CreateSubscriptionUser(requestObject: UserUpdateViewModel): Promise<null> {
        return await this.PostRequest(requestObject);
    }

    async UpdateSubscriptionUser(id: string, requestObject: UserUpdateViewModel): Promise<null> {
        return await this.PutRequest(id, requestObject);
    }

    async RemoveSubscriptionUser(id: string): Promise<null> {
        return await this.DeleteRequest(id);
    }


    async GetBranches(): Promise<BranchLookupBranchViewModel[]> {
        return await this.GetRequest(`branches`);
    }


    private async PostRequest(requestObject: any){
        let url = this.baseUrl;
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: requestObject ? JSON.stringify(requestObject) : ""
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            }).catch((e) => {
                throw new Error(e);
            });
    }

    private async PutRequest(id: string, requestObject: any){
        let url = this.baseUrl + id;
        const requestOptions = {
            method: "PUT",
            headers: authHeader(),
            body: requestObject ? JSON.stringify(requestObject) : ""
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            }).catch((e) => {
                throw new Error(e);
            });
    }
    
    private async GetRequest(path: string) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            }).catch((e) => {
                throw new Error(e);
            });
    }

    private async DeleteRequest(id: string) {
        let url = this.baseUrl + id;
        const requestOptions = {
            method: "DELETE",
            headers: authHeader()
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .catch((e) => {
                throw new Error(e);
            });
    }
} 