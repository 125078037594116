import React, {useState} from "react";
import {Button, DropdownItem, Table} from "reactstrap";
import {UserListViewModel} from "../ViewModels/UserListViewModel";
import {UserRow} from "./UserRow";
import {UserAddRow} from "./UserAddRow";
import {BranchLookupBranchViewModel} from "../ViewModels/BranchLookupBranchViewModel";
import Loader from "react-loaders";
import {FaUserPlus} from "react-icons/all";

interface IUserTableProps {
    tableData: UserListViewModel[];
    branchData: BranchLookupBranchViewModel[];
    handleAdd: (viewModelToAdd: UserListViewModel) => void;
    handleUpdate: (index: number, viewModelToUpdate: UserListViewModel) => void;
    handleDelete: (index: number, id: string) => void;
    isLoading: boolean;
}

export const UserTable = ({
                              tableData,
                              branchData,
                              handleAdd,
                              handleUpdate,
                              handleDelete,
                              isLoading
                          }: IUserTableProps) => {
    const [isAdding, setIsAdding] = useState(false);
    const [isNewRow, setIsNewRow] = useState(false);

    const showAdd = () => {
        setIsAdding(!isAdding);
    }

    const handleSaveNewRow = (viewModelToAdd: UserListViewModel) => {
        handleAdd(viewModelToAdd);
        setIsAdding(false);
    }

    const populateUserTableData = () => {
        return tableData.map((data: UserListViewModel, index: number) => {
            return (
                <UserRow key={index} branchData={branchData} index={index} row={data} handleUpdate={handleUpdate}
                         handleDelete={handleDelete}/>
            )
        })
    }

    return (
        <div className="userSubscriptionTableContainer ">
            {isLoading ?
                <div className="d-flex justify-content-center align-items-center">
                    <Loader type="ball-pulse-sync" active/>
                </div> :
                <Table className="table table-responsive-lg">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Branch Name</th>
                        <th className="align-middle text-center">
                            <Button className="btn btn-outline-secondary text-white"
                                    onClick={showAdd}><FaUserPlus/> Add</Button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {isAdding &&
                        <UserAddRow branchData={branchData} handleAdd={handleSaveNewRow} showAdd={showAdd}/>}
                        {populateUserTableData()}
                    </tbody>
                </Table>
            }
        </div>
    )
}