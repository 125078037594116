import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import { ToastContainer } from "react-toastify";

//Services
import UserService from "../../Pages/Settings/Users/Services/UserService";

//Controllers
import {UserController} from "../../Pages/Settings/Users/Components/UserController";

//Lazy loading
const Quote = lazy(()=> import("../../Pages/Quote"));
const UserPages = lazy(()=> import('../../Pages/User'));
const TcoPages = lazy(() => import('../../Pages/TcoSubscriptions'));


const AppMain = () => {

    return (
        <Fragment>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                    </div>
                </div>
            }>
                <Route path="/users" component={UserPages}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                    </div>
                </div>
            }>
                <Route path="/quote" component={Quote}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/orders" component={TcoPages}/>
            </Suspense>
            
            <Suspense fallback={ 
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync" />
                        </div>
                    </div>
                </div>
            }>
                <Route exact path="/user/list" render={(props) => {
                    return(<UserController props={props} userService={new UserService()}/>)
                }}/>
            </Suspense>
            
            <Route exact path="/" render={() => (
                <Redirect to="quote/newquote"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
